import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MANAGER_FEATURE_KEY, ManagerState} from './manager.reducer';

export const authState =
	createFeatureSelector<ManagerState>(MANAGER_FEATURE_KEY);

export const loaded = createSelector(
	authState,
	(state: ManagerState) => state.loaded
);
export const error = createSelector(
	authState,
	(state: ManagerState) => state.error
);

export const screensavers = createSelector(
	authState,
	(state: ManagerState) => state.screensavers
);

export const optionGroups = createSelector(
	authState,
	(state: ManagerState) => state.optionGroups
);

export const tables = createSelector(
	authState,
	(state: ManagerState) => state.tables
);

export const venue = createSelector(
	authState,
	(state: ManagerState) => state.venue
);

export const articles = createSelector(
	authState,
	(state: ManagerState) => state.articles
);
export const optionArticles = createSelector(
	authState,
	(state: ManagerState) => state.optionArticles
);
export const articlePagination = createSelector(
	authState,
	(state: ManagerState) => state.articlePagination
);
export const optionAtriclePagination = createSelector(
	authState,
	(state: ManagerState) => state.optionAtriclePagination
);

export const categories = createSelector(
	authState,
	(state: ManagerState) => state.categories
);

//listen orders

export const orders = createSelector(
	authState,
	(state: ManagerState) => state.orders
);

export const orderStatusFiler = createSelector(
	authState,
	(state: ManagerState) => state.orderStatusFiler
);
export const filtersStockManager = createSelector(
	authState,
	(state: ManagerState) => state.filters
);
export const messageUpdate = createSelector(
	authState,
	(state: ManagerState) => state.messageUpdate
);

export const errorSyncUber = createSelector(
	authState,
	(state: ManagerState) => state.errorSyncUber
);
export const errorSyncJet = createSelector(
	authState,
	(state: ManagerState) => state.errorSyncJet
);
export const errorSyncWolt = createSelector(
	authState,
	(state: ManagerState) => state.errorSyncWolt
);
export const successSync = createSelector(
	authState,
	(state: ManagerState) => state.successSync
);
export const statusUber = createSelector(
	authState,
	(state: ManagerState) => state.statusUber
);
export const statusWolt = createSelector(
	authState,
	(state: ManagerState) => state.statusWolt
);
export const salesOverview = createSelector(
	authState,
	(state: ManagerState) => state.salesOverview
);
