<div class="container">
	<mat-card class="main-card">
		<mat-card-content class="card-content">
			<h3 class="title">{{'login_page.title' | translate}}</h3>
			<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
				<mat-form-field appearance="fill" class="full-width">
					<mat-label>{{'login_page.email_label' | translate}}</mat-label>
					<input
						matInput
						formControlName="email"
						[placeholder]="'login_page.email_label' | translate"
						type="email"
					/>
				</mat-form-field>
				<mat-form-field appearance="fill" class="full-width">
					<mat-label>{{'login_page.password_label' | translate}}</mat-label>
					<input
						matInput
						formControlName="password"
						[placeholder]="'login_page.password_label' | translate"
						type="password"
					/>
				</mat-form-field>
				<div>
					<button
						mat-raised-button
						type="submit"
						class="submit-button full-width"
					>
						{{'login_page.login' | translate}}
					</button>
				</div>
			</form>
		</mat-card-content>
		<div class="side-content">
			<div
				class="logo"
				style="
					background-image: url('https://dev-admin.smoothr.de/img/smoothr_logo.def9972e.svg');
				"
			></div>
			<p class="description">{{'login_page.short_info' | translate}}</p>
		</div>
	</mat-card>
</div>
