<div class="wrapper">
	<h3 class="title">
		{{ 'stock_manager.question_sync' | translate }}
	</h3>
	<div class="external-button-wrapper">
		@if (data.externalOrderProviders; as providerValue) {
			@if (providerValue.wolt.enabled) {
				<button class="wolt-btn" (click)="syncWolt()" [disabled]="disabledWolt">
					<h3>{{ 'stock_manager.sync' | translate }}</h3>
					<img src="../../../../assets/wolt.svg" alt />
				</button>
			}
			@if (providerValue.jet.enabled) {
				<button class="jet-btn" (click)="syncJet()" [disabled]="disableJet">
					<h3>{{ 'stock_manager.sync' | translate }}</h3>
					<img src="../../../../assets/lieferando.png" alt />
				</button>
			}
			@if (providerValue.uberEats.enabled) {
				<button class="uber-btn" (click)="syncUber()" [disabled]="disableUber">
					<h3>{{ 'stock_manager.sync' | translate }}</h3>
					<img src="../../../../assets/uber_eats.png" alt />
				</button>
			}
		}
	</div>
	<div class="button-wrapper">
		<button class="close" (click)="close()">
			{{ 'common.close' | translate }}
		</button>
	</div>
</div>
