import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {combineLatest, of, switchMap} from 'rxjs';
import {CheckoutIntegrationStatus} from 'src/app/models/CheckoutIntegrationStatus';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {Venue} from 'src/app/models/Venue';
import {NavigationService} from 'src/app/services/navigation.service';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-manager-header',
	templateUrl: './manager-header.component.html',
	styleUrls: ['./manager-header.component.scss']
})
export class ManagerHeaderComponent implements OnInit {
	@Input() venue: Venue | null;
	@Input() showBack = false;
	@Input() showChip = false;
	@Input() showFaq = false;
	@Input() showNotification = false;
	@Input() showMessages = false;
	@Input() listenShowBack = false;
	@Output() backEvent = new EventEmitter<void>();
	OrderStatus = OrderStatus;
	filtersChips = [
		{
			status: OrderStatus.FAILED,
			quantity: 0,
			color: '#B0063E',
			selected: false
		},
		{status: null, quantity: 0, color: '#B0063E', selected: false},
		{
			status: OrderStatus.IN_PREPARATION,
			quantity: 0,
			color: '#FD5E00',
			selected: false
		},
		{status: OrderStatus.READY, quantity: 0, color: '#34A853', selected: false},
		{status: OrderStatus.DONE, quantity: 0, color: '#A9A9A9', selected: false}
	];
	filtersChips$ = combineLatest(
		this.managerFacade.orders$,
		this.managerFacade.orderStatusFiler$
	).pipe(
		switchMap(([orders, status]) => {
			const statusCount = orders.reduce((acc, obj) => {
				if (
					obj.checkoutIntegrationStatus ===
						CheckoutIntegrationStatus.REJECTED ||
					obj.checkoutIntegrationStatus === CheckoutIntegrationStatus.CANCELED
				) {
					acc[OrderStatus.FAILED] = (acc[OrderStatus.FAILED] || 0) + 1;
					return acc;
				}

				if (obj.status) {
					acc[obj?.status] = (acc[obj?.status] || 0) + 1;
				}
				return acc;
			}, {});

			console.log('statusCount', statusCount);

			this.filtersChips = this.filtersChips.map(chip => {
				chip.selected = false;
				if (status == chip.status) {
					chip.selected = true;
				}
				if (!chip.status) {
					chip.quantity = orders.filter(
						it =>
							it.status !== OrderStatus.DONE && it.status !== OrderStatus.FAILED
					).length;
				}
				if (chip.status) {
					chip.quantity =
						statusCount[chip.status] !== undefined
							? statusCount[chip.status]
							: 0;
				}
				return chip;
			});

			return of(this.filtersChips);
		})
	);
	constructor(
		private managerFacade: ManagerFacade,
		private navService: NavigationService
	) {}

	ngOnInit() {}

	async onBurgerClick() {
		// await this.navService.adminConsole();
		await this.navService.pinCode();
	}

	async navigateBack() {
		if (this.listenShowBack) {
			this.backEvent.emit();
			return;
		}
		this.navService.managerPage();
	}

	getColor(status: string) {
		let defaultColor = '#B0063E';
		switch (status) {
			case OrderStatus.DONE:
				defaultColor = '#A9A9A9';
				break;
			case OrderStatus.READY:
				defaultColor = '#34A853';
				break;
			case OrderStatus.IN_PREPARATION:
				defaultColor = '#FD5E00';
				break;
			default:
				break;
		}
		return defaultColor;
	}
	selectItem(status: OrderStatus | null) {
		this.managerFacade.setFilterOrderStatus(status);
	}
	back() {
		this.navService.managerPage();
	}
}
