<app-manager-header
	[venue]=" venue$  | async"
	[showBack]="true"
	[listenShowBack]="true"
	(backEvent)="listenGoBack()"
></app-manager-header>
<div class="wrapper">
	<div class="wrapper-header">
		<h1 class="title">{{ "stock_management.title" | translate }}</h1>
		@if (salesOverview$ | async; as sales) {
		<mat-card>
			<mat-card-content>
				<table class="table">
					<thead>
						<tr>
							@for (header of headers; track $index) {
							<th
								colspan="2"
								class="border-first-child-l border-r text-center align-center"
							>
								<div class="provider-title">
									@if (['wolt', 'jet', 'uber_eats', 'terminal',
									'web_app'].includes(header.value)) {
									<img
										[src]="'../../../assets/' + header.value + '.png'"
										class="provider-icon"
										[alt]="header.text"
										[ngStyle]="getImageDimensions(header.value)"
									/>
									}
									<span>{{ header.text }}</span>
								</div>
								@if (header.value !== 'total') {
								<div class="text_small">
									{{ "stock_management.total_orders" | translate }}: {{
									getTotalOrdersByProvider(sales, header.value) }}
								</div>
								<div class="text_small">
									{{ "stock_management.total_amout" | translate }}: {{
									getTotalAmountByProvider(sales, header.value) }}
								</div>
								}
							</th>
							}
						</tr>
						<tr>
							@for (header of headers; track $index) {
							<th class="text-center border-first-child-l border-b text_small">
								{{ "stock_management.orders" | translate }}
							</th>
							<th class="border-r text-center border-b text_small">
								{{ "stock_management.amount" | translate }}
							</th>
							}
						</tr>
					</thead>

					<tbody>
						@for (item of sales; track $index) {
						<tr>
							@for (h of headers.slice(0, headers.length - 1); track $index) {
							<td class="text-center border-first-child-l border-b text_small">
								{{ getTotalOrders(item.sales, h.value) }}
							</td>
							<td class="border-r text-center border-b text_small">
								{{ getTotalAmount(item.sales, h.value) }}
							</td>
							}

							<td class="text-center border-b text_small">
								{{ item.totalOrders }}
							</td>
							<td class="border-r text-center border-b text_small">
								€{{ item.totalAmount?.$numberDecimal }}
							</td>
						</tr>
						}
					</tbody>
				</table>
			</mat-card-content>
		</mat-card>
		}
	</div>
</div>
