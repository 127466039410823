import {
	Component,
	ElementRef,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';

import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {combineLatest, filter, map, Subject, takeUntil} from 'rxjs';

import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {OrderType} from 'src/app/models/OrderType';
import * as moment from 'moment';
import {Socket} from 'ngx-socket-io';
import {SocketRole} from 'src/app/models/SocketRoles';
import Order from 'src/app/models/Order';
import {OrderService, STATUS_CHANGE} from 'src/app/services/order.service';
import {Table} from 'src/app/models/Table';
import {NavigationService} from 'src/app/services/navigation.service';
import {ArticleModalComponent} from 'src/app/components/article-modal/article-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {CheckoutIntegrationStatus} from 'src/app/models/CheckoutIntegrationStatus';
import {AppComponent} from 'src/app/app.component';

@Component({
	selector: 'app-manger',
	templateUrl: './manger.page.html',
	styleUrls: ['./manger.page.scss']
})
export class ManagerPage implements OnDestroy, OnInit {
	@ViewChild('scrollableContent', {static: false})
	scrollableContent!: ElementRef;

	private readonly onDestroy$ = new Subject<void>();
	private readonly stopListeningSocketForTable$ = new Subject<void>();
	public venue$ = this.managerFacade.venue$;
	public orders$ = combineLatest(
		this.managerFacade.orders$,
		this.managerFacade.orderStatusFiler$
	).pipe(
		map(([ordersValue, status]) => {
			let orders = JSON.parse(JSON.stringify(ordersValue)) as Order[];
			console.log('ORDER STAUTS FILETS', status);
			console.log('LIST ORDERS', orders);
			const statusOrder = [
				OrderStatus.FAILED,
				null,
				OrderStatus.AWAITING_CONFIRMATION,
				OrderStatus.IN_PREPARATION,
				OrderStatus.READY,
				OrderStatus.DONE
			];
			orders = orders.filter(
				it =>
					it.checkoutIntegrationStatus !== CheckoutIntegrationStatus.CANCELED
			);
			if (orders.length > 0)
				orders.sort((a, b) => {
					return (
						statusOrder.indexOf(a?.status ?? null) -
						statusOrder.indexOf(b?.status ?? null)
					);
				});
			if (status != OrderStatus.DONE) {
				orders = orders.filter(it => it.status != OrderStatus.DONE);
			}

			if (!status) {
				orders = orders.filter(
					it =>
						it.status === OrderStatus.IN_PREPARATION ||
						it.status === OrderStatus.AWAITING_CONFIRMATION ||
						it.status === OrderStatus.READY
				);
				return orders;
			}
			orders = orders.filter(it => it.status == status);
			return orders;
		})
	);
	public statusChangeENUM = STATUS_CHANGE;
	constructor(
		private authFacade: AuthFacade,
		private managerFacade: ManagerFacade,
		private socket: Socket,
		private orderService: OrderService,
		private navService: NavigationService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.loadDataByUser(venueId);
				}
			});

		this.managerFacade.tables$
			.pipe(
				filter(device => device.length > 0),
				takeUntil(this.stopListeningSocketForTable$ || this.onDestroy$)
			)
			.subscribe(tables => {
				console.log('table', tables);
				if (tables.length > 0) {
					this.listenSocket(tables);
				}
			});
	}
	private loadDataByUser(venueId: string) {
		this.managerFacade.loadVenueData({venueId});
		this.loadOrdersWithDefaultConfig(venueId);
	}
	ngOnDestroy() {
		this.onDestroy$.next();
	}
	private loadOrdersWithDefaultConfig(venueId: string) {
		this.managerFacade.loadOrders({
			venue: venueId,
			status: [
				OrderStatus.AWAITING_CONFIRMATION,
				OrderStatus.IN_PREPARATION,
				OrderStatus.READY,
				OrderStatus.DONE
			],
			orderTypes: [
				OrderType.TERMINAL,
				OrderType.STANDARD,
				OrderType.VIRTUAL,
				OrderType.PREORDER
			],
			from: moment().startOf('day').toString(),
			to: moment().add(2, 'days').toString(),
			promoCode: true
		});

		this.listenSocketOrders(venueId);
	}
	private listenSocketOrders(venueId: string) {
		console.log('here');
		this.socket.on('connect', () => {
			this.authenticateSocket({
				roles: [SocketRole.COUNTER_DISPLAY, SocketRole.COUNTER_DISPLAY_MAIN],
				venueIds: [venueId]
			});
		});

		this.socket.on('venue-' + venueId + '-orders', (data: any) => {
			if (data?.order && data?.type == 'update') {
				console.log('LISTEN', data);
				if (data?.order?.status == OrderStatus.IN_PREPARATION) {
					this.playClickSound();
				}
				this.updateState(data.order);
			}
		});
	}
	private updateState(order: Order) {
		this.managerFacade.updateOrder(order);
	}
	private authenticateSocket(data: {
		roles: SocketRole[];
		venueIds: string[];
	}): void {
		this.socket.emit('authenticate', data.roles, data.venueIds, (ack: any) => {
			console.log('Socket authenticated for ', ack, data);
		});
	}
	private listenSocket(tables: Table[]) {
		console.log('load Socket');
		// tables.forEach(it => {
		// 	this.socket.on(
		// 		`terminalStatus_${it.venue}_${it.number.replace(' ', '_')}`,
		// 		(data: any) => {
		// 			console.log('EMIT STATUS', data);
		// 			this.managerFacade.updateTable({...it, status: data ?? 'Failed'});
		// 		}
		// 	);
		// });
	}
	updateStatus(data: {order: Order; status: STATUS_CHANGE}) {
		if (!data || !data.order || !data.status) {
			return;
		}
		switch (data.status) {
			case STATUS_CHANGE.PREV:
				this.managerFacade.updateOrderStatus(
					data.order,
					this.orderService.getPreviousOrderStatus(data.order.status)
				);
				break;
			case STATUS_CHANGE.NEXT:
				this.managerFacade.updateOrderStatus(
					data.order,
					this.orderService.getNextOrderStatus(data.order.status)
				);
				break;
			default:
				break;
		}
	}
	stockManager() {
		this.navService.stockManager();
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(ArticleModalComponent, {
			data: {articleName: 'aperol'}
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(result);
		});
	}

	scrollLeft() {
		this.scrollableContent.nativeElement.scrollBy({
			left: -500,
			behavior: 'smooth'
		});
	}

	scrollRight() {
		this.scrollableContent.nativeElement.scrollBy({
			left: 500,
			behavior: 'smooth'
		});
	}
	playClickSound() {
		if (AppComponent.musicOn) {
			const audio = new Audio('assets/sound.mp3');
			audio.load(); // This ensures the audio is preloaded
			audio.play();
		}
	}
}
