import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import Order from 'src/app/models/Order';
import {STATUS_CHANGE} from 'src/app/services/order.service';

@Component({
	selector: 'app-manager-order-item-draw',
	templateUrl: './manager-order-item-draw.component.html',
	styleUrls: ['./manager-order-item-draw.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerOrderItemDrawComponent implements OnInit {
	orderValue: Order;
	@Input() set order(value: Order) {
		console.log('ORDER', value);
		this.orderValue = value;
	}
	@Output() sendOrderStatusChange = new EventEmitter<{
		order: Order;
		status: STATUS_CHANGE;
	}>();

	constructor() {}

	ngOnInit() {}
	updateStatus(data: {order: Order; status: STATUS_CHANGE}) {
		this.sendOrderStatusChange.emit(data);
	}
}
