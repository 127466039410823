import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-ask-permission-dialog',
	templateUrl: './ask-permission-dialog.component.html',
	styleUrls: ['./ask-permission-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AskPermissionDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<AskPermissionDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {text: string}
	) {
		console.log(data.text);
	}

	ngOnInit() {}
	close() {
		this.dialogRef.close(false);
	}
	agree() {
		this.dialogRef.close(true);
	}
}
