import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import Order from 'src/app/models/Order';
import {OrderType} from 'src/app/models/OrderType';
import {PaymentMethod} from 'src/app/models/PaymentMethod';
import {PreorderType} from 'src/app/models/PreorderType';
import {TerminalorderType} from 'src/app/models/TerminalorderType';
import {OrderService, numberToCurrency} from 'src/app/services/order.service';

@Component({
	selector: 'app-order-item-header-info',
	templateUrl: './order-item-header-info.component.html',
	styleUrls: ['./order-item-header-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemHeaderInfoComponent implements OnInit {
	public order: Order;
	numberToCurrency = numberToCurrency;
	public type: PreorderType | TerminalorderType | null;
	public paymentMethod: PaymentMethod | null;
	public paymentMethodEnum = PaymentMethod;
	public orderType = OrderType;
	@Input() set orderValue(value: Order) {
		if (value) {
			this.order = value;
			this.type = this.orderService.getOrderPreorderOrTerminalType(value);
			this.paymentMethod = this.orderService.getPaymentMethod(value);
		}
	}
	constructor(private orderService: OrderService) {}

	ngOnInit() {}

	getColorByMethod(method: PaymentMethod | null) {
		switch (method) {
			case PaymentMethod.CASH:
				return '#1F3A7F';

			case PaymentMethod.CREDIT_CARD:
				return '#8480BB';

			default:
				return '#1F3A7F';
		}
	}
}
