import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

export const NAVIGATIONURLS = {
	manager: () => 'manager',
	login: () => 'login',
	stockManager: () => 'stock-manager',
	salesOverview: () => 'sales-overview',
	adminConsole: () => 'admin-console',
	pinCode: () => 'pin-code',
	timeSettings: () => 'time-settings',
	openingHours: () => 'opening-hours',
	faq: () => 'faq'
};

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	constructor(private router: Router) {}

	async login() {
		await this.router.navigateByUrl(NAVIGATIONURLS.login());
	}
	async managerPage() {
		await this.router.navigateByUrl(NAVIGATIONURLS.manager());
	}
	async stockManager() {
		await this.router.navigateByUrl(NAVIGATIONURLS.stockManager());
	}
	async adminConsole() {
		await this.router.navigateByUrl(NAVIGATIONURLS.adminConsole());
	}
	async pinCode() {
		await this.router.navigateByUrl(NAVIGATIONURLS.pinCode());
	}
	async openingHours() {
		await this.router.navigateByUrl(NAVIGATIONURLS.openingHours());
	}
	async faq() {
		await this.router.navigateByUrl(NAVIGATIONURLS.faq());
	}
}
