import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {
	BehaviorSubject,
	EMPTY,
	Subject,
	catchError,
	filter,
	map,
	takeUntil
} from 'rxjs';
import {Venue} from 'src/app/models/Venue';
import {ApiService} from 'src/app/services/api.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

export enum DeliveryProviderTime {
	PREPARATION = 'preparation',
	DELIVERY = 'delivery'
}

@Component({
	selector: 'app-time-settings',
	templateUrl: './time-settings.page.html',
	styleUrl: './time-settings.page.scss'
})
export class TimeSettingsPage implements OnDestroy, OnInit {
	public offsetsFormSmoothrDelivery: FormGroup;
	public offsetsFormSmoothrTakeAway: FormGroup;

	public woltTimeControlDelivery = new FormControl<number>(0, [
		Validators.max(360)
	]);
	public woltTimeControlPreparation = new FormControl<number>(0, [
		Validators.max(360)
	]);

	public uberTimeControlPreparation = new FormControl<number>(0, [
		Validators.max(360)
	]);
	public loaderUber$ = new BehaviorSubject<boolean>(false);
	public loaderWolt$ = new BehaviorSubject<boolean>(false);
	public loadingSmoothr$ = new BehaviorSubject<boolean>(false);
	private readonly onDestroy$ = new Subject<void>();
	public venueData$ = new BehaviorSubject<Venue | null>(null);
	public deliveryProviderTime = DeliveryProviderTime;
	constructor(
		private navService: NavigationService,
		private managerFacade: ManagerFacade,
		private authFacade: AuthFacade,
		private fb: FormBuilder,
		private apiService: ApiService,
		private toaster: ToastrService,
		private translate: TranslateService
	) {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.loadDataByUser(venueId);
				}
			});
		this.offsetsFormSmoothrDelivery = this.fb.group({
			first: [0, [Validators.max(1000)]],
			last: [0, [Validators.max(1000)]],
			offset: [0, [Validators.max(1000)]]
		});
		this.offsetsFormSmoothrTakeAway = this.fb.group({
			first: [0, [Validators.max(1000)]],
			last: [0, [Validators.max(1000)]],
			offset: [0, [Validators.max(1000)]]
		});
	}
	ngOnInit() {
		this.managerFacade.venue$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(venue => {
				if (venue) {
					this.venueData$.next(venue);
					this.setupSmoothrTime(venue);
					this.setupDeliveryProviders(venue);
				}
			});
	}
	private setupDeliveryProviders(venue: Venue) {
		this.woltTimeControlDelivery.patchValue(
			venue?.externalOrderProviders?.wolt?.deliveryTime ?? 0
		);
		this.woltTimeControlPreparation.patchValue(
			venue?.externalOrderProviders?.wolt?.preparationTime ?? 0
		);
		this.uberTimeControlPreparation.patchValue(
			venue?.externalOrderProviders?.uberEats?.preparationTime ?? 0
		);
	}
	private setupSmoothrTime(venue: Venue) {
		this.offsetsFormSmoothrDelivery.patchValue({
			first: venue.offsets.preorder.delivery.first,
			last: venue.offsets.preorder.delivery.last,
			offset: venue.offsets.preorder.delivery.offset
		});

		this.offsetsFormSmoothrTakeAway.patchValue({
			first: venue.offsets.preorder.takeAway.first,
			last: venue.offsets.preorder.takeAway.last,
			offset: venue.offsets.preorder.takeAway.offset
		});
	}
	private loadDataByUser(venueId: string) {
		this.managerFacade.loadVenueData({venueId});
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	getBack() {
		this.navService.adminConsole();
	}
	public saveSmoothrTime() {
		const copyVenue = JSON.parse(
			JSON.stringify(this.venueData$.getValue())
		) as Venue;
		this.updateOffsetWithQuery(copyVenue);
	}
	public updateSmoothrOffsetsType() {}
	private updateOffsetWithQuery(venue: Venue) {
		if (!venue) {
			return;
		}
		venue.offsets.preorder.takeAway = this.offsetsFormSmoothrTakeAway.value;
		venue.offsets.preorder.delivery = this.offsetsFormSmoothrDelivery.value;
		this.loadingSmoothr$.next(true);
		this.apiService
			.updateVenueDelivery(venue)
			.pipe(
				catchError(e => {
					this.loadingSmoothr$.next(false);
					return EMPTY;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(result => {
				if (result && result?._id) {
					this.venueData$.next(result);
				}
				console.log('VEnue Result', result);
				this.loadingSmoothr$.next(false);
			});
	}

	sendTimeWolt(type: DeliveryProviderTime) {
		const copyVenue = JSON.parse(
			JSON.stringify(this.venueData$.getValue())
		) as Venue;

		if (!copyVenue) {
			return;
		}
		this.loaderWolt$.next(true);
		if (type === DeliveryProviderTime.PREPARATION) {
			this.apiService
				.changeOrderOffsetWolt(copyVenue._id, {
					preparationTime: Number(this.woltTimeControlPreparation.value),
					deliveryTime: copyVenue.externalOrderProviders.wolt.deliveryTime
				})
				.pipe(
					catchError(e => {
						console.log(e);

						console.log(e.error.message);

						this.loaderWolt$.next(false);
						this.toaster.error(
							this.translate.instant('prep_time_input_dialog.errors.wolt'),
							e?.error?.message ?? ''
						);
						throw EMPTY;
					})
				)
				.subscribe(v => {
					this.toaster.success(
						this.translate.instant('prep_time_input_dialog.success.wolt')
					);
					this.loaderWolt$.next(false);
				});
		}
		if (type === DeliveryProviderTime.DELIVERY) {
			this.apiService
				.changeOrderOffsetWolt(copyVenue._id, {
					preparationTime:
						copyVenue.externalOrderProviders.wolt.preparationTime,
					deliveryTime: Number(this.woltTimeControlDelivery.value)
				})
				.pipe(
					catchError(e => {
						console.log(e.error.message);

						this.loaderWolt$.next(false);
						this.toaster.error(
							this.translate.instant('prep_time_input_dialog.errors.wolt'),
							e?.error?.message ?? ''
						);
						throw EMPTY;
					})
				)
				.subscribe(v => {
					this.toaster.success(
						this.translate.instant('prep_time_input_dialog.success.wolt')
					);
					this.loaderWolt$.next(false);
				});
		}
	}
	sendTimeUber() {
		const copyVenue = JSON.parse(
			JSON.stringify(this.venueData$.getValue())
		) as Venue;

		if (!copyVenue) {
			return;
		}
		this.loaderUber$.next(true);
		this.apiService
			.changeOrderOffsetUber(copyVenue._id, {
				preparationTime: Number(this.uberTimeControlPreparation.value)
			})
			.pipe(
				catchError(e => {
					this.loaderUber$.next(false);
					this.toaster.error(
						this.translate.instant('prep_time_input_dialog.errors.uber_eats'),
						e?.error?.message ?? ''
					);
					throw EMPTY;
				})
			)
			.subscribe(v => {
				this.toaster.success(
					this.translate.instant('prep_time_input_dialog.success.uber_eats')
				);
				this.loaderUber$.next(false);
			});
	}
	navigateBack() {
		this.navService.adminConsole();
	}
}
