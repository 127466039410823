<div class="order-card">
	<div class="order-header" *ngIf="orderValue">
		<app-order-item-header-info
			[orderValue]="orderValue"
		></app-order-item-header-info>
	</div>

	<app-order-item-status-section
		[orderValue]="orderValue"
		(sendOrderStatusChange)="updateStatus($event)"
	></app-order-item-status-section>

	<div class="order-details">
		<div class="order-item" *ngFor="let item of orderValue.orderedArticles">
			<app-order-item-articlegroup-draw
				[articleGroup]="item"
				[orderStatus]="orderValue.status"
			></app-order-item-articlegroup-draw>
		</div>
	</div>
</div>
