import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Venue} from 'src/app/models/Venue';

@Component({
	selector: 'app-smoothr-input-offsets-dialog',
	templateUrl: './smoothr-input-offsets-dialog.component.html',
	styleUrls: ['./smoothr-input-offsets-dialog.component.scss']
})
export class SmoothrInputOffsetsDialogComponent implements OnInit {
	public offsetsFormSmoothrDelivery: FormGroup;
	public offsetsFormSmoothrTakeAway: FormGroup;

	constructor(
		public dialogRef: MatDialogRef<SmoothrInputOffsetsDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			venue: Venue;
		},
		private fb: FormBuilder
	) {
		this.offsetsFormSmoothrDelivery = this.fb.group({
			first: [0, [Validators.max(1000)]],
			last: [0, [Validators.max(1000)]],
			offset: [0, [Validators.max(1000)]]
		});
		this.offsetsFormSmoothrTakeAway = this.fb.group({
			first: [0, [Validators.max(1000)]],
			last: [0, [Validators.max(1000)]],
			offset: [0, [Validators.max(1000)]]
		});

		this.offsetsFormSmoothrDelivery.patchValue({
			first: data.venue.offsets.preorder.delivery.first,
			last: data.venue.offsets.preorder.delivery.last,
			offset: data.venue.offsets.preorder.delivery.offset
		});

		this.offsetsFormSmoothrTakeAway.patchValue({
			first: data.venue.offsets.preorder.takeAway.first,
			last: data.venue.offsets.preorder.takeAway.last,
			offset: data.venue.offsets.preorder.takeAway.offset
		});
	}

	ngOnInit() {}
	close() {
		this.dialogRef.close();
	}
	save() {
		this.dialogRef.close({
			deliveryOffset: this.offsetsFormSmoothrDelivery.value,
			take_awayOffset: this.offsetsFormSmoothrTakeAway.value
		});
	}
}
