import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {ManagerFacade} from '../../store/manager/+state/manager.facade';
import {NavigationService} from '../../services/navigation.service';
import {MatDialog} from '@angular/material/dialog';
import {filter, map, Subject, takeUntil} from 'rxjs';
import {environment} from '../../../environments/environment';
import {select, Store} from '@ngrx/store';
import * as ManagerSelectors from '../../store/manager/+state/manager.selectors';
import * as moment from 'moment';
import {AuthFacade} from '../../store/auth-state/+state/auth.facade';

@Component({
	selector: 'app-sales-overview',
	templateUrl: './sales-overview.page.html',
	styleUrl: './sales-overview.page.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesOverviewPage implements OnInit, OnDestroy {
	private readonly onDestroy$ = new Subject<void>();
	public venueId: string = '';
	public venue$ = this.managerFacade.venue$;
	readonly salesOverview$ = this.store.pipe(
		select(ManagerSelectors.salesOverview)
	);

	public headers = [
		{text: 'Wolt', value: 'wolt'},
		{text: 'Jet', value: 'jet'},
		{text: 'Uber Eats', value: 'uber_eats'},
		{text: 'Terminal', value: 'terminal'},
		{text: 'Web App', value: 'web_app'},
		{text: 'Total', value: 'total'}
	];

	constructor(
		private managerFacade: ManagerFacade,
		private navService: NavigationService,
		public dialog: MatDialog,
		private store: Store,
		private authFacade: AuthFacade
	) {}

	ngOnInit() {
		this.managerFacade.resetSalesOverview();
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe(venueId => {
				if (venueId) {
					this.managerFacade.loadVenueData({venueId});
				}
			});
		this.venue$.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
			if (!value) return;
			const from = moment().format('YYYY-MM-DD');
			const to = moment().add(1, 'days').format('YYYY-MM-DD');
			this.managerFacade.loadSalesOverview(
				value._id,
				from,
				to,
				environment.customerGroup
			);
		});
	}

	listenGoBack() {
		this.navService.adminConsole();
	}

	getTotalOrders(sales: any[], provider: string): number | string {
		const sale = sales?.find(s => s.provider === provider);
		return sale ? sale.totalOrders : '-';
	}

	getTotalAmount(sales: any[], provider: string): string {
		const sale = sales?.find(s => s.provider === provider);
		return sale
			? `€${parseFloat(sale.totalAmount.$numberDecimal).toFixed(2)}`
			: '-';
	}

	getImageDimensions(value: string) {
		const dimensions = {
			wolt: {width: '30px', height: '20px'},
			jet: {width: '80px', height: '30px'},
			uber_eats: {width: '50px', height: '20px'},
			terminal: {width: '20px', height: '20px'},
			web_app: {width: '20px', height: '20px'}
		};
		return dimensions[value] || {width: '40px', height: '20px'};
	}

	getTotalOrdersByProvider(items: any[], provider: string) {
		let totalOrders = 0;

		items.forEach(venue => {
			venue.sales.forEach(sale => {
				if (sale.provider === provider) {
					totalOrders += sale.totalOrders;
				}
			});
		});

		return totalOrders;
	}

	getTotalAmountByProvider(items: any[], provider: string) {
		let totalAmount = 0;

		items.forEach(venue => {
			venue.sales.forEach(sale => {
				if (sale.provider === provider) {
					totalAmount += parseFloat(sale.totalAmount.$numberDecimal);
				}
			});
		});

		return `€${totalAmount.toFixed(2)}`;
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
}
