import {NgModule} from '@angular/core';
import {NoPreloading, RouterModule, Routes} from '@angular/router';
import {LoginPage} from './pages/auth/login/login.page';
import {AuthGuard} from './guards/auth.guard';
import {NAVIGATIONURLS} from './services/navigation.service';
import {ManagerPage} from './pages/manager/manger.page';
import {StockManagerPage} from './pages/stock-manager/stock-manager.page';
import {AdminConsolePage} from './pages/admin console/admin-console.page';
import {PinCodePage} from './pages/pin-code/pin-code.page';
import {ManagerOpenGuard} from './guards/manager-open.guard';
import {OpeningHoursPage} from './pages/opening-hours/opening-hours.page';
import {FaqPage} from './pages/faq/faq.page';
import {TimeSettingsPage} from './pages/time-settings/time-settings.page';
import {SalesOverviewPage} from './pages/sales-overview/sales-overview.page';

const routes: Routes = [
	{
		path: '',
		redirectTo: NAVIGATIONURLS.login(),
		pathMatch: 'full'
	},
	{
		path: NAVIGATIONURLS.login(),
		component: LoginPage
	},
	{
		path: NAVIGATIONURLS.manager(),
		component: ManagerPage,
		canActivate: [AuthGuard]
	},
	{
		path: NAVIGATIONURLS.stockManager(),
		component: StockManagerPage
		// canActivate: [AuthGuard, ManagerOpenGuard]
	},
	{
		path: NAVIGATIONURLS.salesOverview(),
		component: SalesOverviewPage
		// canActivate: [AuthGuard, ManagerOpenGuard]
	},
	{
		path: NAVIGATIONURLS.adminConsole(),
		component: AdminConsolePage
		// canActivate: [AuthGuard, ManagerOpenGuard]
	},
	{
		path: NAVIGATIONURLS.pinCode(),
		component: PinCodePage,
		canActivate: [AuthGuard, ManagerOpenGuard]
	},
	{
		path: NAVIGATIONURLS.openingHours(),
		component: OpeningHoursPage,
		canActivate: [ManagerOpenGuard]
	},
	{
		path: NAVIGATIONURLS.faq(),
		component: FaqPage,
		canActivate: [ManagerOpenGuard]
	},
	{
		path: NAVIGATIONURLS.timeSettings(),
		component: TimeSettingsPage
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {preloadingStrategy: NoPreloading})],
	exports: [RouterModule]
})
export class AppRoutingModule {}
