import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnInit
} from '@angular/core';
import {filter, map} from 'rxjs';
import {AuthFacade} from 'src/app/store/auth-state/+state/auth.facade';
import {ManagerFacade} from 'src/app/store/manager/+state/manager.facade';

@Component({
	selector: 'app-admin-console',
	templateUrl: './admin-console.page.html',
	styleUrl: './admin-console.page.scss',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminConsolePage implements OnInit {
	private managerFacade = inject(ManagerFacade);
	private authFacade = inject(AuthFacade);

	public venue$ = this.managerFacade.venue$;

	ngOnInit(): void {
		this.authFacade.userData$
			.pipe(
				filter(userValue => !!userValue),
				map(userData => {
					return userData?.venues[0] ?? null;
				})
			)
			.subscribe(venueId => {
				if (venueId) {
					this.managerFacade.loadVenueData({venueId});
				}
			});
	}
}
