<div class="wrapper">
	<h2 class="title">{{ data.text }}</h2>
	<div class="footer-btn">
		<button class="dismiss" (click)="close()">
			<h3>{{ 'common.close' | translate }}</h3>
		</button>

		<button (click)="agree()">
			<h3>{{ 'common.yes' | translate }}</h3>
		</button>
	</div>
</div>
