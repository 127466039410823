const {version} = require('../../package.json');

export const environment = {
	apiUrl: 'https://dev-api.smoothr.de',
	production: false,
	socketRole: 'MONITORING_UI',
	version,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	pinCode: 9500,
	customerGroup: 'losteria'
};
