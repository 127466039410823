<div class="wrapper">
	<!-- <h2 class="title">
		{{ 'prep_time_input_dialog.title_types.' + data.type | translate }}
	</h2> -->
	<div class="img-wrapper">
		<ng-container *ngIf="data.type == deliveryProvider.WOLT">
			<img src="../../../assets/wolt.svg" alt />
		</ng-container>
		<ng-container *ngIf="data.type == deliveryProvider.UBER">
			<img src="../../../assets/uber_eats.png" alt />
		</ng-container>
	</div>

	<div class="input_wrapper">
		<ng-container *ngIf="data.type == deliveryProvider.WOLT">
			<div class="section-input">
				<h3 class="small-title">{{ 'admin_console.preptime' | translate }}</h3>
				<div class="section_with_btn">
					<div class="input-group">
						<input
							max="360"
							pattern="[0-9]"
							type="number"
							[formControl]="woltTimeControlPreparation"
							placeholder="0"
						/>
						<span>(min)</span>
					</div>

					<button
						[disabled]="
							!woltTimeControlPreparation?.value || (loaderWolt$ | async)
						"
						(click)="sendTimeWolt()"
					>
						@if (loaderWolt$ | async; as value) {
							<span class="loader"></span>
						} @else {
							<h3>{{ 'common.save' | translate }}</h3>
						}
					</button>
				</div>
				<div class="form-value">
					<span> {{ 'prep_time_input_dialog.value_exceed' | translate }} </span>
				</div>
			</div>
			<div class="section-input">
				<h3 class="small-title">
					{{ 'admin_console.delivery_time' | translate }}
				</h3>
				<div class="section_with_btn">
					<div class="input-group">
						<input
							max="360"
							pattern="[0-9]"
							type="number"
							[formControl]="woltTimeControlDelivery"
							placeholder="0"
						/>
						<span>(min)</span>
					</div>

					<button
						[disabled]="
							!woltTimeControlDelivery?.value || (loaderUber$ | async)
						"
						(click)="sendTimeUber()"
					>
						@if (loaderUber$ | async; as value) {
							<span class="loader"></span>
						} @else {
							<h3>{{ 'common.save' | translate }}</h3>
						}
					</button>
				</div>
				<div class="form-value">
					<span> {{ 'prep_time_input_dialog.value_exceed' | translate }} </span>
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="data.type == deliveryProvider.UBER">
			<div class="section-input">
				<h3 class="small-title">{{ 'admin_console.preptime' | translate }}</h3>
				<div class="section_with_btn">
					<div class="input-group">
						<input
							max="360"
							pattern="[0-9]"
							type="number"
							[formControl]="uberTimeControlPreparation"
							placeholder="0"
						/>
						<span>(min)</span>
					</div>

					<button
						[disabled]="
							!uberTimeControlPreparation?.value || (loaderUber$ | async)
						"
						(click)="sendTimeUber()"
					>
						@if (loaderUber$ | async; as value) {
							<span class="loader"></span>
						} @else {
							<h3>{{ 'common.save' | translate }}</h3>
						}
					</button>
				</div>
				<div class="form-value">
					<span> {{ 'prep_time_input_dialog.value_exceed' | translate }} </span>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="footer-btn">
		<button (click)="close()">
			<h3>{{ 'common.close' | translate }}</h3>
		</button>
	</div>
</div>
