import {Component, EventEmitter, Input, Output} from '@angular/core';
import Order from 'src/app/models/Order';
import {OrderStatus} from 'src/app/models/OrderStatus';
import {PreorderType} from 'src/app/models/PreorderType';
import {TerminalorderType} from 'src/app/models/TerminalorderType';
import {OrderService, STATUS_CHANGE} from 'src/app/services/order.service';

@Component({
	selector: 'app-order-item-status-section',
	templateUrl: './order-item-status-section.component.html',
	styleUrls: ['./order-item-status-section.component.scss']
})
export class OrderItemStatusSectionComponent {
	public order: Order;
	public orderStatusEnum = OrderStatus;
	public type: PreorderType | TerminalorderType | null;
	@Input() set orderValue(value: Order) {
		if (value) {
			this.order = value;
			this.type = this.orderSevice.getOrderPreorderOrTerminalType(value);
		}
	}
	@Output() sendOrderStatusChange = new EventEmitter<{
		order: Order;
		status: STATUS_CHANGE;
	}>();
	statusChangeENUM = STATUS_CHANGE;
	constructor(private orderSevice: OrderService) {}
	updateStatus(order: Order, status: STATUS_CHANGE) {
		this.sendOrderStatusChange.emit({order, status});
	}
}
