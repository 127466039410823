<div
	*ngIf="order"
	class="block_info"
	[class.done]="order.status == orderStatusEnum.DONE"
	[class.green]="order.status == orderStatusEnum.READY"
	[class.orange]="order.status == orderStatusEnum.IN_PREPARATION"
>
	@if (order.status === orderStatusEnum.FAILED) {
		<p class="status_text">{{ 'failed_title' | translate }}</p>
	} @else {
		<p class="status_text">{{ 'status.' + order.status | translate }}</p>
	}

	<div class="code_info">
		<button
			[class.disabled]="order.status === orderStatusEnum.FAILED"
			mat-icon-button
			(click)="updateStatus(order, statusChangeENUM.PREV)"
		>
			<img
				[class.inactive]="order.status == orderStatusEnum.AWAITING_CONFIRMATION"
				src="/assets/icons/arrow_left.svg"
				alt
			/>
		</button>

		<div>
			<p>
				{{ order.code }}
			</p>
			@if (order?.secondaryCode) {
				<p>
					{{ order.secondaryCode }}
				</p>
			}
		</div>

		<button
			[class.disabled]="order.status === orderStatusEnum.FAILED"
			mat-icon-button
			(click)="updateStatus(order, statusChangeENUM.NEXT)"
		>
			<img
				[class.inactive]="order.status == orderStatusEnum.DONE"
				src="/assets/icons/arrow_right.svg"
				alt
			/>
		</button>
	</div>
	<div>
		@if (
			order.method == 'wolt' ||
			order.method === 'uber_eats' ||
			order.method === 'jet'
		) {
			<div class="user_info_external">
				<p *ngIf="order.orderAt">
					{{ 'user_info.' + type + '_date_time' | translate }}
				</p>
				<p *ngIf="order.orderAt">
					{{ order.orderAt | date: 'dd/MM/YYY HH:mm' }}
				</p>
				<p *ngIf="order?.preorder?.name">
					{{ order.preorder.name }}
				</p>
				<p *ngIf="order?.preorder?.phone">
					{{ order.preorder.phone }}
				</p>
			</div>
		}
		@if (
			order.preorder &&
			order.preorder.type &&
			!(
				order.method == 'wolt' ||
				order.method === 'uber_eats' ||
				order.method === 'jet'
			)
		) {
			<div class="user_info_external">
				<p *ngIf="order?.preorder?.name">
					{{ order.preorder.name }}
				</p>
				<p *ngIf="order?.preorder?.phone">
					{{ order.preorder.phone }}
				</p>
				<p *ngIf="order.preorder?.note || order.note">
					Note: {{ order.preorder.note }} || {{ order.note }}
				</p>
			</div>
		}
		@if (order.terminalorder && order.terminalorder.type && order.note) {
			<div class="user_info_external">
				<p *ngIf="order?.note">Buzzer Code: {{ order.note }}</p>
			</div>
		}
		@if (order && order?.preorder?.type === preorderType.DELIVERY) {
			<div class="user_info_external">
				<p>Lieferzeit: {{ formatTime(order.asap, order?.orderAt) }}</p>
				<p>
					Address: {{ order?.preorder?.street }} {{ order?.preorder?.number }},
					{{ order?.preorder?.postalCode }} {{ order?.preorder?.city }}
				</p>
			</div>
		}
	</div>
</div>
