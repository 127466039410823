import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
// import { TranslateService } from '@ngx-translate/core';
// import { ToastrService } from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs';
import ExternalOrderProviders from 'src/app/models/ExternalOrderProviders';
// import { ApiService } from 'src/app/services/api.service';

export enum DeliveryProvider {
	UBER = 'uber_eats',
	WOLT = 'wolt'
}
@Component({
	selector: 'app-prep-time-input-dialog',
	templateUrl: './prep-time-input-dialog.component.html',
	styleUrls: ['./prep-time-input-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrepTimeInputDialogComponent implements OnInit {
	public woltTimeControlDelivery = new FormControl<number>(0, [
		Validators.max(360)
	]);
	public woltTimeControlPreparation = new FormControl<number>(0, [
		Validators.max(360)
	]);

	public uberTimeControlPreparation = new FormControl<number>(0, [
		Validators.max(360)
	]);
	public loaderUber$ = new BehaviorSubject<boolean>(false);
	public loaderWolt$ = new BehaviorSubject<boolean>(false);
	public deliveryProvider = DeliveryProvider;
	constructor(
		public dialogRef: MatDialogRef<PrepTimeInputDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			type: DeliveryProvider;
			value: ExternalOrderProviders;
			venueId: string;
		}
	) {
		// private apiService: ApiService,
		// private toaster: ToastrService,
		// private translate: TranslateService
		console.log(data);
		if (data.type === DeliveryProvider.WOLT) {
			this.woltTimeControlDelivery.patchValue(
				data?.value?.wolt?.deliveryTime ?? 0
			);
			this.woltTimeControlPreparation.patchValue(
				data?.value?.wolt?.preparationTime ?? 0
			);
		}
		if (data.type === DeliveryProvider.UBER) {
			this.uberTimeControlPreparation.patchValue(
				data?.value?.wolt?.preparationTime ?? 0
			);
		}
		// if (
		// 	data.type === DeliveryProviderTime.PREPARATION &&
		// 	data?.value?.wolt?.preparationTime
		// ) {
		// 	this.woltTimeControl.patchValue(data?.value?.wolt?.preparationTime ?? 0);
		// }
		// if (
		// 	data.type === DeliveryProviderTime.DELIVERY &&
		// 	data?.value?.wolt?.deliveryTime
		// ) {
		// 	this.woltTimeControl.patchValue(data?.value?.wolt?.deliveryTime ?? 0);
		// }

		// if (
		// 	data.type === DeliveryProviderTime.PREPARATION &&
		// 	data?.value?.uberEats?.preparationTime
		// ) {
		// 	this.uberTimeControl.patchValue(
		// 		data?.value?.uberEats?.preparationTime ?? 0
		// 	);
		// }
		// this.woltTimeControl.valueChanges.subscribe(value => {
		// 	if (value && value > 360) {
		// 		this.woltTimeControl.setValue(360, { emitEvent: false });
		// 	}
		// 	if (value && value < 0) {
		// 		this.woltTimeControl.setValue(0, { emitEvent: false });
		// 	}
		// });
		// this.uberTimeControl.valueChanges.subscribe(value => {
		// 	if (value && value > 360) {
		// 		this.uberTimeControl.setValue(360, { emitEvent: false });
		// 	}
		// 	if (value && value < 0) {
		// 		this.uberTimeControl.setValue(0, { emitEvent: false });
		// 	}
		// });
	}

	ngOnInit() {}
	close() {
		this.dialogRef.close();
	}
	sendTimeWolt() {
		if (!this.data.venueId) {
			return;
		}
		this.loaderWolt$.next(true);
		// if (this.data.type === DeliveryProviderTime.PREPARATION) {
		// 	this.apiService
		// 		.changeOrderOffsetWolt(this.data.venueId, {
		// 			preparationTime: Number(this.woltTimeControl.value),
		// 			deliveryTime: this.data.value.wolt.deliveryTime
		// 		})
		// 		.pipe(
		// 			catchError(e => {
		// 				console.log(e);

		// 				console.log(e.error.message);

		// 				this.loaderWolt$.next(false);
		// 				this.toaster.error(
		// 					this.translate.instant('prep_time_input_dialog.errors.wolt'),
		// 					e?.error?.message ?? ''
		// 				);
		// 				throw EMPTY;
		// 			})
		// 		)
		// 		.subscribe(v => {
		// 			this.toaster.success(
		// 				this.translate.instant('prep_time_input_dialog.success.wolt')
		// 			);
		// 			this.loaderWolt$.next(false);
		// 		});
		// }
		// if (this.data.type === DeliveryProviderTime.DELIVERY) {
		// 	this.apiService
		// 		.changeOrderOffsetWolt(this.data.venueId, {
		// 			preparationTime: this.data.value.wolt.preparationTime,
		// 			deliveryTime: Number(this.woltTimeControl.value)
		// 		})
		// 		.pipe(
		// 			catchError(e => {
		// 				console.log(e.error.message);

		// 				this.loaderWolt$.next(false);
		// 				this.toaster.error(
		// 					this.translate.instant('prep_time_input_dialog.errors.wolt'),
		// 					e?.error?.message ?? ''
		// 				);
		// 				throw EMPTY;
		// 			})
		// 		)
		// 		.subscribe(v => {
		// 			this.toaster.success(
		// 				this.translate.instant('prep_time_input_dialog.success.wolt')
		// 			);
		// 			this.loaderWolt$.next(false);
		// 		});
		// }
	}
	sendTimeUber() {
		if (!this.data.venueId) {
			return;
		}
		this.loaderUber$.next(true);

		// if (this.data.type === DeliveryProviderTime.PREPARATION) {
		// 	this.apiService
		// 		.changeOrderOffsetUber(this.data.venueId, {
		// 			preparationTime: Number(this.uberTimeControl.value)
		// 		})
		// 		.pipe(
		// 			catchError(e => {
		// 				this.loaderUber$.next(false);
		// 				this.toaster.error(
		// 					this.translate.instant('prep_time_input_dialog.errors.uber_eats'),
		// 					e?.error?.message ?? ''
		// 				);
		// 				throw EMPTY;
		// 			})
		// 		)
		// 		.subscribe(v => {
		// 			this.toaster.success(
		// 				this.translate.instant('prep_time_input_dialog.success.uber_eats')
		// 			);
		// 			this.loaderUber$.next(false);
		// 		});
		// }
	}
}
