<div class="wrapper">
	<div class="logo_wrapper">
		<img src="../../../assets/tresen/smoothr_logo.svg" alt />
	</div>

	<div class="smoothr-block">
		<div class="form-block" [formGroup]="offsetsFormSmoothrDelivery">
			<h2 class="title">
				{{ 'input_offset_dialog.title_delivery' | translate }}
			</h2>
			<div class="form-item">
				<h3>{{ 'input_offset_dialog.earlier_offset' | translate }}</h3>
				<div class="input-group">
					<input
						max="1000"
						formControlName="first"
						pattern="[0-9]"
						type="number"
					/>
					<span>(min)</span>
				</div>
			</div>
			<div class="form-item">
				<h3>{{ 'input_offset_dialog.minumum_offset' | translate }}</h3>
				<div class="input-group">
					<input
						max="1000"
						formControlName="offset"
						pattern="[0-9]"
						type="number"
					/>
					<span>(min)</span>
				</div>
			</div>
			<div class="form-item">
				<h3>{{ 'input_offset_dialog.latest_offset' | translate }}</h3>
				<div class="input-group">
					<input
						max="1000"
						formControlName="last"
						pattern="[0-9]"
						type="number"
					/>
					<span>(min)</span>
				</div>
			</div>
		</div>
		<div class="form-block" [formGroup]="offsetsFormSmoothrTakeAway">
			<h2 class="title">
				{{ 'input_offset_dialog.title_take_away' | translate }}
			</h2>
			<div class="form-item">
				<h3>{{ 'input_offset_dialog.earlier_offset' | translate }}</h3>
				<div class="input-group">
					<input
						max="1000"
						formControlName="first"
						pattern="[0-9]"
						type="number"
					/>
					<span>(min)</span>
				</div>
			</div>
			<div class="form-item">
				<h3>{{ 'input_offset_dialog.minumum_offset' | translate }}</h3>
				<div class="input-group">
					<input
						max="1000"
						formControlName="offset"
						pattern="[0-9]"
						type="number"
					/>
					<span>(min)</span>
				</div>
			</div>
			<div class="form-item">
				<h3>{{ 'input_offset_dialog.latest_offset' | translate }}</h3>
				<div class="input-group">
					<input
						max="1000"
						formControlName="last"
						pattern="[0-9]"
						type="number"
					/>
					<span>(min)</span>
				</div>
			</div>
		</div>
	</div>

	<div class="footer-btn">
		<button (click)="close()">
			<h3>{{ 'common.close' | translate }}</h3>
		</button>
		<button (click)="save()" class="save">
			<h3>{{ 'common.save' | translate }}</h3>
		</button>
	</div>
</div>
