<app-manager-header
	[venue]=" venueData$  | async"
	[showBack]="false"
></app-manager-header>

<div class="wrapper">
	<div class="header-back">
		<button
			mat-stroked-button
			color="primary"
			class="back-button"
			(click)="navigateBack()"
		>
			<mat-icon>keyboard_arrow_left</mat-icon>
			{{ 'common.get_back' | translate }}
		</button>
	</div>
	<div class="content-wrapper">
		<div class="wrapper-smoothr">
			<div class="logo_wrapper">
				<img src="../../../assets/tresen/smoothr_logo.svg" alt />
			</div>

			<div class="smoothr-block">
				<div class="form-block" [formGroup]="offsetsFormSmoothrDelivery">
					<div class="title-block">
						<h2 class="title">
							{{ ('input_offset_dialog.title_delivery' )| translate }}
						</h2>
						<img src="../../../assets/tresen/delivery.svg" alt />
					</div>

					<div class="form-item">
						<h3>{{'input_offset_dialog.earlier_offset' | translate}}</h3>
						<div class="input-group">
							<input
								max="1000"
								formControlName="first"
								pattern="[0-9]"
								type="number"
							/>
							<span>(min)</span>
						</div>
					</div>
					<div class="form-item">
						<h3>{{'input_offset_dialog.minumum_offset' | translate}}</h3>
						<div class="input-group">
							<input
								max="1000"
								formControlName="offset"
								pattern="[0-9]"
								type="number"
							/>
							<span>(min)</span>
						</div>
					</div>
					<div class="form-item">
						<h3>{{'input_offset_dialog.latest_offset' | translate}}</h3>
						<div class="input-group">
							<input
								max="1000"
								formControlName="last"
								pattern="[0-9]"
								type="number"
							/>
							<span>(min)</span>
						</div>
					</div>
				</div>
				<div class="form-block" [formGroup]="offsetsFormSmoothrTakeAway">
					<div class="title-block">
						<h2 class="title">
							{{ ('input_offset_dialog.title_take_away' )| translate }}
						</h2>
						<img src="../../../assets/tresen/take_away.svg" alt />
					</div>

					<div class="form-item">
						<h3>{{'input_offset_dialog.earlier_offset' | translate}}</h3>
						<div class="input-group">
							<input
								max="1000"
								formControlName="first"
								pattern="[0-9]"
								type="number"
							/>
							<span>(min)</span>
						</div>
					</div>
					<div class="form-item">
						<h3>{{'input_offset_dialog.minumum_offset' | translate}}</h3>
						<div class="input-group">
							<input
								max="1000"
								formControlName="offset"
								pattern="[0-9]"
								type="number"
							/>
							<span>(min)</span>
						</div>
					</div>
					<div class="form-item">
						<h3>{{'input_offset_dialog.latest_offset' | translate}}</h3>
						<div class="input-group">
							<input
								max="1000"
								formControlName="last"
								pattern="[0-9]"
								type="number"
							/>
							<span>(min)</span>
						</div>
					</div>
				</div>
			</div>

			<div class="footer-btn">
				<button
					(click)="saveSmoothrTime()"
					class="save"
					[disabled]=" (loadingSmoothr$ | async)"
				>
					@if (loadingSmoothr$ | async; as value) {
					<span class="loader"></span>
					} @else {
					<h3>{{ 'common.save' | translate }}</h3>
					}
				</button>
			</div>
		</div>
		<div class="external-providers">
			<div class="wrapper-wolt">
				<div class="logo_wrapper">
					<img src="../../../assets/wolt.svg" alt />
				</div>
				<p class="time_description">
					{{'admin_console.wolt_time_desc' | translate}}
				</p>
				<div class="wrapper-section">
					<div class="section-input">
						<h3 class="small-title">
							{{ 'admin_console.preptime' | translate }}
						</h3>
						<div class="section_with_btn">
							<div class="input-group">
								<input
									max="360"
									pattern="[0-9]"
									type="number"
									[formControl]="woltTimeControlPreparation"
									placeholder="0"
								/>
								<span>(min)</span>
							</div>

							<button
								[disabled]="!woltTimeControlPreparation?.value || (loaderWolt$ | async)"
								(click)="sendTimeWolt(deliveryProviderTime.PREPARATION)"
							>
								@if (loaderWolt$ | async; as value) {
								<span class="loader"></span>
								} @else {
								<h3>{{ 'common.save' | translate }}</h3>
								}
							</button>
						</div>
						<div class="form-value">
							<span>
								{{ 'prep_time_input_dialog.value_exceed' | translate }}
							</span>
						</div>
					</div>
					<div class="section-input">
						<h3 class="small-title">
							{{ 'admin_console.delivery_time' | translate }}
						</h3>
						<div class="section_with_btn">
							<div class="input-group">
								<input
									max="360"
									pattern="[0-9]"
									type="number"
									[formControl]="woltTimeControlDelivery"
									placeholder="0"
								/>
								<span>(min)</span>
							</div>

							<button
								[disabled]="!woltTimeControlDelivery?.value || (loaderWolt$ | async)"
								(click)="sendTimeWolt(deliveryProviderTime.DELIVERY)"
							>
								@if (loaderWolt$ | async; as value) {
								<span class="loader"></span>
								} @else {
								<h3>{{ 'common.save' | translate }}</h3>
								}
							</button>
						</div>
						<div class="form-value">
							<span>
								{{ 'prep_time_input_dialog.value_exceed' | translate }}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="wrapper-wolt">
				<div class="logo_wrapper margin-top">
					<img src="../../../assets/uber_eats.png" alt />
				</div>
				<p class="time_description">
					{{'admin_console.uber_time_desc' | translate}}
				</p>
				<div class="wrapper-section">
					<div class="section-input">
						<h3 class="small-title">
							{{ 'admin_console.preptime' | translate }}
						</h3>
						<div class="section_with_btn">
							<div class="input-group">
								<input
									max="360"
									pattern="[0-9]"
									type="number"
									[formControl]="uberTimeControlPreparation"
									placeholder="0"
								/>
								<span>(min)</span>
							</div>

							<button
								[disabled]="!uberTimeControlPreparation?.value || (loaderUber$ | async)"
								(click)="sendTimeUber()"
							>
								@if (loaderUber$ | async; as value) {
								<span class="loader"></span>
								} @else {
								<h3>{{ 'common.save' | translate }}</h3>
								}
							</button>
						</div>
						<div class="form-value">
							<span>
								{{ 'prep_time_input_dialog.value_exceed' | translate }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
