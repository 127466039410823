import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FullscreenService {
	constructor() {}

	enterFullscreen(): void {
		const elem = document.documentElement as any; // or use a specific element
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) {
			// Firefox
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) {
			// Chrome, Safari and Opera
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) {
			// IE/Edge
			elem.msRequestFullscreen();
		}
	}

	exitFullscreen(): void {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
	}

	isFullscreen(): boolean {
		return document.fullscreenElement ? true : false;
	}
}
