<div class="wrapper">
	<h1 class="title">
		{{ data.name.de || data.name.en }} ({{
			'common.option_groups' | translate
		}}: {{ data.groups.length }}) <br /><span class="plu">
			Plu: ({{ data.number }})
		</span>
	</h1>
	<div class="items">
		@for (item of data.groups; track $index) {
			<app-article-item-with-groups-show
				(selectedArticlesIsActiveForChange)="
					selectedArticlesIsActiveForChange($event)
				"
				(selectedArticlesForChange)="selectedArticlesForChange($event)"
				[optionGroupValue]="item"
			></app-article-item-with-groups-show>
		}
	</div>
	<div class="buttons">
		<button mat-stroked-button (click)="close()">
			{{ 'article_modal.close' | translate }}
		</button>
		<button mat-flat-button color="accent" (click)="save()">
			{{ 'article_modal.confirm' | translate }}
		</button>
	</div>
</div>
